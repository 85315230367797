/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API } from "../../config.json";
import axios from "axios";
import { Redirect } from "react-router";
import { authenticate } from "../Auth/CheckUser";
import * as AiIcons from "react-icons/ai";
import { useHistory, Link } from "react-router-dom";

import Swal from "sweetalert2";

const Login = (props) => {

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [err, setError] = useState("");
  // const [success, setSuccess] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = values;
    const user = {
      email,
      password,
    };
    axios
      .post(`${API}login.php`, user)
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          let jwt = res.data.token;
          let expire = res.data.token_expired;

          localStorage.setItem("Access_token", jwt);
          localStorage.setItem("Expire", expire);
          document.cookie =
            "token=" + jwt + ';path="https://open-rev.com/backend/admin"';
          document.cookie = "expires=" + expire + ";";
          Swal.fire({
            title: "Success!",
            text: `${res.data.message}`,
            icon: "success",
          }).then(function () {
            window.location.href = "/dashboard";
          });
        } else {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.error(`Error: ${err}`);
      });
  };
  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const isAuth = authenticate();
  let errMsg = "";
  // let successMsg = "";
  if (err) {
    errMsg = <div className="error-msg">{err}</div>;
  }
  // if (success) {
  //   successMsg = <div className="success-msg">{success}</div>;
  // }
  return (
    <>
      {isAuth ? <Redirect to="/dashboard" /> : null}

      <div className="auth-wrapper">
        <div className="auth-inner">
          <div style={{ float: "right" }}>
            <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
          </div>
          <form onSubmit={handleSubmit}>
            <h3>Login</h3>
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                value={values.name}
                className="form-control"
                placeholder="Enter email"
                onChange={onChange("email")}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                value={values.password}
                type="password"
                className="form-control"
                placeholder="Enter password"
                onChange={onChange("password")}
              />
            </div>
            {/* <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div> */}
            {errMsg}
            {/* {successMsg} */}
            <button type="submit" className="btn btn-primary btn-block">
              Submit
            </button>
            {/*eslint-disable-next-line */}
            <p className="forgot-password text-right">
              <Link className="newLink" to="/forgot-password">
                {" "}
                Forgot password?
              </Link>
            </p>
            <p className="forgot-password text-right">
              <Link className="newLink" to="/register">
                Don't have an account yet?
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
