import React from "react";
import "./Search.css";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = ({ input: keyword, onChange: setKeyword }) => {
  return (
    <>
      <input
        className="searchInput"
        key="random1"
        value={keyword}
        placeholder={"Search Works"}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <button className="searchButton" href="#">
        <i className="material-icons">
          <AiOutlineSearch size={20} />
        </i>
      </button>
    </>
  );
};

export default SearchBar;
