import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../../config.json";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";

function EditProfile(props) {
  let location = useLocation();
  let userInfoDef = location.state;
  let userInfo = userInfoDef.userInfo;
  const [values, setValues] = useState({
    name: userInfo.name,
    surname: userInfo.surname,
    email: userInfo.email,
  });
  const [err, setError] = useState("");

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, surname, email } = values;
    const body = {
      name,
      surname,
      email,
      guid: userInfo.GUID,
    };
    await axios
      .post(`${API}routes/update_profile.php`, body)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: `${res.data.message}`,
            icon: "success",
          }).then(function () {
            window.location.href = "/";
          });
        } else if (res.data.status === 422) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  let err_fn_msg = "";
  if (err && err.first_name_msg) {
    err_fn_msg = <div className="error-msg">{err.first_name_msg}</div>;
  } else {
    err_fn_msg = "";
  }

  let err_ln_msg = "";
  if (err && err.last_name_msg) {
    err_ln_msg = <div className="error-msg">{err.last_name_msg}</div>;
  }

  let err_email_msg = "";
  if (err && (err.email_format_msg || err.email_exist_msg)) {
    if (err.email_format_msg) {
      err_email_msg = <div className="error-msg">{err.email_format_msg}</div>;
    } else {
      err_email_msg = <div className="error-msg">{err.email_exist_msg}</div>;
    }
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div style={{ float: "right" }}>
          <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
        </div>
        <form onSubmit={handleSubmit}>
          <h3>Edit Profile </h3>

          <div className="form-group">
            <label>Username *</label>
            <input
              type="text"
              value={values.name}
              className="form-control"
              placeholder="First name"
              onChange={onChange("name")}
            />
          </div>
          {err_fn_msg}
          <div className="form-group">
            <label>Last name *</label>
            <input
              type="text"
              value={values.surname}
              onChange={onChange("surname")}
              className="form-control"
              placeholder="Last name"
            />
          </div>
          {err_ln_msg}
          <div className="form-group">
            <label>Email address *</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={values.email}
              disabled={true}
              // onChange={onChange("email")}
            />
          </div>
          {err_email_msg}
          <button
            name="submit"
            type="submit"
            className="btn btn-primary btn-block"
          >
            Make changes
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
