/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import axios from "axios";
import { API } from "../../config.json";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import { useHistory } from "react-router-dom";

const Register = (props) => {
  const [err, setError] = useState("");
  const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, surname, email, password, confirm_password } = values;
    const user = {
      name,
      surname,
      email,
      password,
      confirm_password,
    };

    await axios
      .post(
        `${API}register.php`,

        user
      )
      .then((res) => {
        res.data.status !== 200
          ? setError(res.data)
          : Swal.fire({
              title: "Success!",
              text: `${res.data.success_msg}`,
              icon: "success",
            }).then(function () {
              props.history.push("/login");
            });

        // if (res.data.status !== 200) {
        //   setError(res.data.err);
        // } else {
        //   Swal.fire({
        //     title: "Success!",
        //     text: `${res.data.success_msg}`,
        //     icon: "success",
        //   }).then(function () {
        //     props.history.push("/login");
        //   });
        // }
      });
  };

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  let err_fn_msg = "";
  if (err && err.first_name_msg) {
    err_fn_msg = <div className="error-msg">{err.first_name_msg}</div>;
  } else {
    err_fn_msg = "";
  }

  let err_ln_msg = "";
  if (err && err.last_name_msg) {
    err_ln_msg = <div className="error-msg">{err.last_name_msg}</div>;
  }

  let err_email_msg = "";
  if (err && (err.email_format_msg || err.email_exist_msg)) {
    if (err.email_format_msg) {
      err_email_msg = <div className="error-msg">{err.email_format_msg}</div>;
    } else {
      err_email_msg = <div className="error-msg">{err.email_exist_msg}</div>;
    }
  }
  let err_pwd_msg = "";

  if (err && err.password_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_msg}</div>;
  }
  if (err && err.password_match_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_match_msg}</div>;
  }
  if (err && err.password_char_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_char_msg}</div>;
  }
  if (err && err.password_digit_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_digit_msg}</div>;
  }
  if (err && err.password_letter_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_letter_msg}</div>;
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div style={{ float: "right" }}>
          <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
        </div>
        <form onSubmit={handleSubmit}>
          <h3>Register</h3>

          <div className="form-group">
            <label>First name *</label>
            <input
              type="text"
              value={values.name}
              className="form-control"
              placeholder="First name"
              onChange={onChange("name")}
            />
          </div>
          {err_fn_msg}
          <div className="form-group">
            <label>Last name *</label>
            <input
              type="text"
              value={values.surname}
              className="form-control"
              placeholder="Last name"
              onChange={onChange("surname")}
            />
          </div>
          {err_ln_msg}
          <div className="form-group">
            <label>Email address *</label>
            <input
              type="email"
              value={values.email}
              onChange={onChange("email")}
              className="form-control"
              placeholder="Enter email"
            />
          </div>
          {err_email_msg}

          <div className="form-group">
            <label>Password *</label>
            <input
              type="password"
              className="form-control"
              value={values.password}
              placeholder="Enter password"
              onChange={onChange("password")}
            />
          </div>
          <div className="form-group">
            <label>Confirm password *</label>
            <input
              type="password"
              className="form-control"
              value={values.confirm_password}
              placeholder="Enter password"
              onChange={onChange("confirm_password")}
            />
          </div>
          <p className="forgot-password text-center p-1 m-2">
            We'll use your personal information in order to improve application
            statistics
          </p>
          {err_pwd_msg}
          <button
            name="submit"
            type="submit"
            className="btn btn-primary btn-block"
          >
            Register
          </button>
          <p className="forgot-password text-right">
            Already registered{" "}
            <Link className="newLink" to="/login">
              sign in?
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
