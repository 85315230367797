function DisplayUserInfo({userInfo}) {
    return (
          <div className="row">
              <div className="col-md-4">
                  <div className="profile-work">
                  <p>WORK LINK</p>
                  <a href="">Website Link</a>
                  <br />
                  <a href="">Bootsnipp Profile</a>
                  <br />
                  <a href="">Bootply Profile</a>
                  <p>SKILLS</p>
                  <a href="">Web Designer</a>
                  <br />
                  <a href="">Web Developer</a>
                  <br />
                  <a href="">WordPress</a>
                  <br />
                  <a href="">WooCommerce</a>
                  <br />
                  <a href="">PHP, .Net</a>
                  <br />
                  </div>
              </div>
              <div className="col-md-8">
                  <p>My uploaded works - total number: {userInfo.works_count}</p>
                  <p>My assessed works - average mark: {userInfo.avg_mark}</p>
                  <p>Given reviews - total number: {userInfo.reviews_count}</p>
                  <p>Given reviews - average mark: {userInfo.avg_review}</p>
                  <p>My quality assessment of other user's reviews - average mark: {userInfo.avg_rev_quality}</p>
                  <p>Other user's quality assessment of my reviews - average mark: {userInfo.avg_my_revs_quality}</p>
              </div>
          </div>
      );
  }
  
  export default DisplayUserInfo;
  