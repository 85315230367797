import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../../config.json";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const [assessmensts, setAssessmensts] = useState([]);
  const [most_recent, setMost_recent] = useState([]);
  const url = `${API}/routes/get_dashboard_data.php?`;

  useEffect(() => {
    function getWorksBySubarea() {
      axios
        .get(url)
        .then((res) => {
          setAssessmensts(res.data.assessments);
          setMost_recent(res.data.most_recent);
        })
        .catch((err) => {
          console.error(`Error: ${err}`);
        });
    }
    getWorksBySubarea();
  }, [url]);

  // const WorkByAssessment = ({ color, bgColor }) => (
  //   <span
  //     style={{
  //       color: color,
  //       background: bgColor,
  //       height: 20,
  //       padding: "1%",
  //     }}
  //   >
  //     Top Rated work by mark:{" "}
  //   </span>
  // );
  // const WorkByRecent = ({ color, bgColor }) => (
  //   <span
  //     style={{
  //       color: color,
  //       background: bgColor,
  //       height: 20,
  //       padding: "1%",
  //     }}
  //   >
  //     Top Rated work by most recent:{" "}
  //   </span>
  // );
  return (
    <>
      <Container className="myCard">
        <Card.Footer
          className="cardFooter"
          style={{
            margin: "0 auto",
            marginTop: "1rem",
            background: "whitesmoke",
            width: "15rem",
          }}
        >
          <i>Filtered by most popular</i>
        </Card.Footer>{" "}
        <Row xl={3} lg={3} md={1} sm={1} xs={1} className="rowCard">
          {assessmensts &&
            assessmensts.map((res, i) => (
              <Col key={i} className="colCard">
                <Link to={`dashboard/works/${encodeURIComponent(res.guid)}`} className="cardLink">
                  <Card>
                    <Card.Body>
                      <Card.Title>{res.title}</Card.Title>
                      <Card.Text>{res.user}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        <i>Published:</i>
                        &nbsp; &nbsp;
                        {res.publish_date}
                      </small>
                    </Card.Footer>
                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
        <Card.Footer
          className="cardFooter"
          style={{
            margin: "0 auto",
            marginBottom: "2%",
            background: "whitesmoke",
            width: "15rem",
          }}
        >
          <i>Filtered by most recent</i>
        </Card.Footer>{" "}
        <Row xl={3} lg={3} md={1} sm={1} xs={1} className="rowCard">
          {most_recent &&
            most_recent.map((res, i) => (
              <Col key={i} className="colCard">
                <Link to={`dashboard/works/${encodeURIComponent(res.guid)}`} className="cardLink">
                  <Card>
                    <Card.Body>
                      <Card.Title>{res.title}</Card.Title>
                      <Card.Text>{res.user}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        <i>Published:</i>
                        &nbsp; &nbsp;
                        {res.publish_date}
                      </small>
                    </Card.Footer>
                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
}
