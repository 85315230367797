import React, { useState, useEffect } from "react";
import { API } from "../../config.json";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function ResetPWD(props) {
  const [values, setValues] = useState({
    password: "",
    confirm_password: "",
  });
  const [checkGuid, setCheckGuid] = useState();
  const [err, setError] = useState();

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const guidFromUrl = props.match.params.guid;
  const copyGuid = guidFromUrl.split("-");
  const originalGuid = copyGuid[0];
  console.log(originalGuid);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirm_password } = values;
    const body = {
      password,
      confirm_password,
      guid: originalGuid,
    };
    await axios
      .post(`${API}routes/store_password.php`, body)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: `${res.data.message}`,
            icon: "success",
          }).then(function () {
            window.location.href = "/";
          });
        } else if (res.data.status === 422) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  const getGuidCheck = () => {
    axios
      .get(`${API}routes/get_guids.php`)
      .then((res) => {
        setCheckGuid(res.data.guid);
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  useEffect(() => {
    getGuidCheck();
  }, []);
  let err_pwd_msg = "";

  if (err && err.password_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_msg}</div>;
  }
  if (err && err.password_not_same) {
    err_pwd_msg = <div className="error-msg">{err.password_not_same}</div>;
  }
  if (err && err.password_match_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_match_msg}</div>;
  }
  if (err && err.password_char_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_char_msg}</div>;
  }
  if (err && err.password_digit_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_digit_msg}</div>;
  }
  if (err && err.password_letter_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_letter_msg}</div>;
  }
  return (
    <>
      {checkGuid && checkGuid.includes(originalGuid) ? (
        <div className="auth-wrapper">
          <div className="auth-inner">
            <form onSubmit={handleSubmit}>
              <h3>Reset Password</h3>

              <div className="form-group">
                <label>Password *</label>
                <input
                  type="password"
                  className="form-control"
                  value={values.password}
                  placeholder="Enter password"
                  onChange={onChange("password")}
                />
              </div>
              <div className="form-group">
                <label>Confirm password *</label>
                <input
                  type="password"
                  className="form-control"
                  value={values.confirm_password}
                  placeholder="Enter password"
                  onChange={onChange("confirm_password")}
                />
              </div>
              {err_pwd_msg}
              <button
                name="submit"
                type="submit"
                className="btn btn-primary btn-block"
              >
                Make changes
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="auth-wrapper">
          <div className="auth-inner">
            <h3 className="mt-5">Your GUID does not match!</h3>
            <h5 className="text-center">
              Send <Link to="/forgot-password">e-mail</Link> again?
            </h5>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPWD;
