import React from "react";
import { Link } from "react-router-dom";

const footer = () => {
  return (
    <footer className="">
      {/* <div className="bottom-footer left">
        <small>
          <Link className="dashLink" to="/term-of-use">
            Term Of Use
          </Link>{" "}
          &nbsp;
          <Link className="dashLink" to="/privacy-police">
            Privacy Police
          </Link>{" "}
          &nbsp;
          <Link className="dashLink" to="/">
            Home
          </Link>{" "}
        </small>
      </div> */}
      <div className="bottom-footer  right">
        <small>© All Right Reserved. Goran Bjelobaba</small>
      </div>
    </footer>
  );
};

export default footer;
