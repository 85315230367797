import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from "../../config.json";
import * as FaColor from "react-icons/fc";
import DisplayUserInfo from "./DisplayUserInfo";

function ChechUserFromUrl(props) {
  const [userInfo, setuserInfo] = useState("");
  const guidFromUrl = props.match.params.guid;

  const getUserProfileFromUrl = async () => {
    await axios
      .get(`${API}routes/user_guid.php?guid=${guidFromUrl}`)
      .then((res) => {
        if (res.data.status === 200) {
          setuserInfo(res.data.userInfo);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  useEffect(() => {
    getUserProfileFromUrl();
  }, []);
  return (
    <div className="container emp-profile">
      {/* <form method="post" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>GUID *</label>
          <input
            required
            type="text"
            value={values.guid}
            onChange={onChange("guid")}
            className="form-control"
            placeholder="enter guid"
          />
        </div>

        <button
          name="submit"
          type="submit"
          className="btn btn-primary btn-block"
        >
          Make changes
        </button>
      </form> */}

      {userInfo ? (
        <>
          {" "}
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <FaColor.FcPortraitMode size={100} />
                {/* <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                        alt=""
                    /> */}
                {/* <div className="file btn btn-lg btn-primary">
                        Change Photo
                        <input type="file" name="file" />
                    </div> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="profile-head">
                <h5>
                  {userInfo.name} {userInfo.surname}
                </h5>
                <h6> {userInfo.GUID}</h6>
                <p className="proile-rating">
                  EMAIL : <span>{userInfo.email}</span>
                </p>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md-3">
    <Link
      to={{ pathname: "/edit-profile", state: { userInfo } }}
      className="dashLink "
    >
      <button className="profile-edit-btn">Edit Profile</button>
    </Link>

    <Link
      to={{ pathname: "/change-password", state: { userInfo } }}
      className="dashLink"
    >
      <button className="profile-edit-btn mt-2">Change Password</button>
    </Link>
  </div> */}
          </div>
          <DisplayUserInfo userInfo={userInfo} />
        </>
      ) : null}
    </div>
  );
}

export default ChechUserFromUrl;
