import React from "react";
import Dashboard from "../sections/Dashboard/Dashboard";
import { Route, Switch } from "react-router";
import Section from "../../HOC/Section";
import Cards from "../sections/Cards/Cards";
import Works from "../sections/Works/Works";
import "react-pro-sidebar/dist/css/styles.css";

function Dash() {
  return (
    <Section id="dashboard">
      <div className="dash-content">
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/dashboard/:subarea" component={Cards} />
          <Route exact path="/dashboard/works/:guid" component={Works} />
        </Switch>
      </div>
    </Section>
  );
}

export default Dash;
