import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../../config.json";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./paginate.css";
const PER_PAGE = 6;
function Cards(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  const subarea = props.match.params.subarea;
  const url = `${API}/routes/get_works_by_subarea.php?subarea=${subarea}`;

  async function getAllPost() {
    const res = await axios.get(url);
    const data = res.data;
    setData(data);
  }

  useEffect(() => {
    getAllPost();
  }, [url]);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentData = data.slice(offset, offset + PER_PAGE).map((post, i) => (
    <Col className="colCard" key={i}>
      <Link to={`works/${encodeURIComponent(post.guid)}`} className="cardLink">
        <Card>
          <Card.Body>
            <Card.Title>{post.title}</Card.Title>
            <Card.Text>{post.user}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">
              <i>Published:</i>
              &nbsp; &nbsp;
              {post.publish_date}
            </small>
          </Card.Footer>
        </Card>
      </Link>
    </Col>
  ));

  const pageCount = Math.ceil(data.length / PER_PAGE);

  return (
    <Container className="myCard">
      <Row xl={3} lg={3} md={2} sm={2} xs={1} className="rowCard">
        {currentData}
      </Row>
      <div className="navigation-pa">
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </Container>
  );
}

export default Cards;
