import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from "../../../config.json";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
const PER_PAGE = 9;

function OrderedWorks() {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const url = `${API}routes/get_sorted_works.php`;

  async function getAllPost() {
    const res = await axios.get(url);
    const data = res.data.data;
    setData(data);
  }
  useEffect(() => {
    getAllPost();
  }, [url]);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentData =
    data &&
    data.slice(offset, offset + PER_PAGE).map((post, i) => (
      <Col className="colCard" key={i}>
        <Link to={`dashboard/works/${encodeURIComponent(post.guid)}`} className="cardLink">
          <Card>
            <Card.Body>
              <Card.Title>{post.title}</Card.Title>
              <Card.Text>{post.user}</Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <i>Avg. Mark:</i>
                &nbsp; &nbsp;
                {post.avg_rate}
              </small>
            </Card.Footer>
          </Card>
        </Link>
      </Col>
    ));

  const pageCount = Math.ceil(data && data.length / PER_PAGE);
  return (
    <div id="dashboard">
      <Container className="myCard" style={{ marginTop: "1rem" }}>
        <Row xl={3} lg={3} md={2} sm={2} xs={1} className="rowCard">
          {currentData}
        </Row>
        <div className="navigation-pa">
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </Container>
    </div>
  );
}

export default OrderedWorks;
