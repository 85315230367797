import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { authenticate } from "../../Auth/CheckUser";
import { API } from "../../../config.json";
import logoIMG from "../../../assets/img/logos.png";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import OutsideClick from "./outsideClick";
const Nav = (props) => {
  const [navClass, setNavClass] = useState("");
  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);

  const isAuth = authenticate();

  const [userInfo, setuserInfo] = useState("");
  const [sidebar, setSidebar] = useState(isAuth ? true : false);
  const [areas, setAreas] = useState([]);
  const [active, setActive] = useState('');

  const showSidebar = () => setSidebar(!sidebar);

  const token = localStorage.getItem("Access_token");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  const logout = () => {
    localStorage.removeItem("Access_token");
    window.location.href = "/";
  };

  useEffect(() => {
    const getUserInfo = async () => {
      await axios.get(`${API}routes/user_info.php`, options).then((res) => {
        setuserInfo(res.data);
        document.cookie = "isAdmin=" + res.data.isAdmin + ";";
      });
    };
    const getAllAreas = async () => {
      await axios
        .get(`${API}routes/get_areas.php`)
        .then((res) => {
          setAreas(res.data);
        })
        .catch((err) => {
          console.error(`Error: ${err}`);
        });
    };
    getAllAreas();
    getUserInfo();

    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //&& !boxOutsideClick
  return (
    <>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"} ref={boxRef}>
        <ProSidebar>
          {isAuth ? (
            <>
              <SidebarHeader>

                { userInfo.isAdmin && 
                    <a className="dashLink" href={`${API}admin`}>
                      <MenuItem icon={<AiIcons.AiFillSetting size={15} />} > Admin </MenuItem>
                    </a>
                }

                <Link to={{ pathname: "/user-profile" }} className="dashLink">
                  <MenuItem
                    icon={<AiIcons.AiOutlineLink size={20} color="grey" />}
                  >
                    {userInfo.name} {userInfo.surname}
                  </MenuItem>
                </Link>
                <a className="dashLink" href={`mailto:${userInfo.email}`}>
                  <MenuItem icon={<AiIcons.AiOutlineMail size={20} />}>
                    {userInfo.email}
                  </MenuItem>
                </a>
                <Link to="/new_work" className="dashLink">
                  <MenuItem
                    icon={<AiIcons.AiOutlineLink size={20} color="grey" />}
                  >
                    Add new work
                  </MenuItem>
                </Link>
                <Link to="/search-page" className="dashLink">
                  <MenuItem
                    icon={<AiIcons.AiOutlineSearch size={20} />}
                    style={{
                      marginBottom: "2rem",
                    }}
                  >
                    Search
                  </MenuItem>
                </Link>
                {/* <MenuItem
                  onClick={logout}
                  icon={<AiIcons.AiOutlineLogout size={20} />}
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  Logout
                </MenuItem> */}
              </SidebarHeader>
            </>
          ) : null}

          <SidebarContent>
            <MenuItem icon={<AiIcons.AiOutlineDashboard size={20} />}>
              <Link to="/dashboard" className="dashLink">
                Dashboard
              </Link>
            </MenuItem>
            <Menu iconShape="square">
              <SubMenu title="Browse">
                <MenuItem>
                  <Link to="/ordered-works" className="dashLink">
                    Ordered works
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/top-reviewers" className="dashLink">
                    Top reviewers
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/top-authors" className="dashLink">
                    Top authors
                  </Link>
                </MenuItem>
              </SubMenu>
              {areas &&
                areas.map((res, i) => (
                  <SubMenu key={i} title={res.area}>
                    {Object.keys(res).map((key) =>
                      // { if(res[key].subarea) {
                      res[key].subarea !== undefined ? (
                        <MenuItem
                          key={res[key].id}
                          style={{ marginBottom: "70% !important" }}
                          className = { active == res[key].id ? 'active' : '' }
                          onClick={ () => setActive(res[key].id) } 
                        >
                          <Link to={`/dashboard/${res[key].subarea}`}>
                            {res[key].subarea}
                          </Link>
                        </MenuItem>
                      ) : (
                        ""
                      )
                    )}
                  </SubMenu>
                ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </nav>

      <nav
        className={`navbar navbar-expand-md mx-auto bg-light ${navClass}`}
        ref={boxRef}
      >
        <div className="container">
          <a className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </a>

          <Link className="navbar-brand" to="/">
            <img
              src={logoIMG}
              alt="Scientific Logo"
            />
            {/* <i className="fas fa-circle ml-1" /> */}
          </Link>
          {isAuth ? (
            <ul className="navbar-nav ml-auto link">
              <li className="nav-item">
                <Link to="/check-user-profile" className="newLink p-2">
                  Check User's profile
                </Link>
              </li>{" "}
              <li className="nav-item">
                <Link to="#" onClick={logout} className="newLink p-2">
                  <AiIcons.AiOutlineLogout size={20} />
                  {"  "}
                  Logout{" "}
                </Link>
              </li>{"  "}
              <li className="nav-item">
                <a href="/Uputstvo za Aplikaciju OpenRev.pdf" className="newLink p-2" target="_blank">
                  Manual
                </a>
              </li>
            </ul>
          ) : isAuth && userInfo.isAdmin ? (
            <ul className="navbar-nav ml-auto link">
              <li className="nav-item">
                <a
                  href="http://open-rev.com/backend/admin"
                  to="/new_work"
                  className="nav-link mobile"
                  style={{ cursor: "pointer" }}
                >
                  <AiIcons.AiFillSetting size={15} /> Admin
                </a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav ml-auto link">
              <li className="nav-item mr-3">
                <Link to="/login" className=" newLink">
                  Login
                </Link>
              </li>
              {"   "}
              <li className="nav-item mr-3">
                <Link to="/register" className=" newLink">
                  Register
                </Link>
              </li>
              {"   "}
              <li className="nav-item">
                <a href="/Uputstvo za Aplikaciju OpenRev.pdf" className=" newLink" target="_blank">
                  Manual
                </a>
              </li>
            </ul>
          )}
          {/* {userInfo.isAdmin ? (
            <ul className="navbar-nav ml-auto link">
              <li className="nav-item">
                <a
                  href="http://open-rev.com/backend/admin"
                  to="/new_work"
                  className="nav-link mobile"
                  style={{ cursor: "pointer" }}
                >
                  <AiIcons.AiFillSetting size={15} /> Admin
                </a>
              </li>
            </ul>
          ) : (
            ""
          )} */}
        </div>
      </nav>
    </>
  );
};

export default Nav;
