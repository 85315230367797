import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from "../../config.json";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import Swal from "sweetalert2";

function ChangePWD(props) {
  const [values, setValues] = useState({
    password: "",
    password_new: "",
    confirm_new_password: "",
  });
  const [err, setError] = useState("");
  let location = useLocation();
  let userInfoDef = location.state;
  let userInfo = userInfoDef.userInfo;
  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirm_new_password, password_new } = values;
    const body = {
      password,
      new_password: password_new,
      confirm_new_password,
      guid: userInfo.GUID,
    };
    await axios
      .post(`${API}routes/change_password.php`, body)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: `${res.data.message}`,
            icon: "success",
          }).then(function () {
            window.location.href = "/";
          });
        } else if (res.data.status === 422) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };

  let err_pwd_msg = "";

  if (err && err.password_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_msg}</div>;
  }
  if (err && err.password_not_same) {
    err_pwd_msg = <div className="error-msg">{err.password_not_same}</div>;
  }
  if (err && err.password_match_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_match_msg}</div>;
  }
  if (err && err.password_char_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_char_msg}</div>;
  }
  if (err && err.password_digit_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_digit_msg}</div>;
  }
  if (err && err.password_letter_msg) {
    err_pwd_msg = <div className="error-msg">{err.password_letter_msg}</div>;
  }
  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <div style={{ float: "right" }}>
            <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
          </div>
          <form onSubmit={handleSubmit}>
            <h3>Change Password</h3>

            <div className="form-group">
              <label>Password *</label>
              <input
                type="password"
                className="form-control"
                value={values.password}
                placeholder="Enter old password"
                onChange={onChange("password")}
              />
            </div>
            <div className="form-group">
              <label>New password *</label>
              <input
                type="password"
                className="form-control"
                value={values.password_new}
                placeholder="Enter new password"
                onChange={onChange("password_new")}
              />
            </div>
            <div className="form-group">
              <label>Confirm password *</label>
              <input
                type="password"
                className="form-control"
                value={values.confirm_new_password}
                placeholder="Confirm new password"
                onChange={onChange("confirm_new_password")}
              />
            </div>
            {err_pwd_msg}
            <button
              name="submit"
              type="submit"
              className="btn btn-primary btn-block"
            >
              Make changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePWD;
