import React, { Fragment } from "react";

import Sections from "../sections/Sections";
const Layout = () => {
  return (
    <Fragment>
      <main>
        <Sections />
      </main>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default Layout;
