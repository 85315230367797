import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const WorkList = ({ workList = [] }) => {
  return (
    <>
      {workList.map((res, i) => {
        if (res) {
          return (
            <Col key={i} className="colCard">
              <Link to={`dashboard/works/${encodeURIComponent(res.guid)}`} className="cardLink">
                <Card>
                  <Card.Body>
                    <Card.Title>{res.title}</Card.Title>
                    <Card.Text>{res.user}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      <i>Published:</i>
                      &nbsp; &nbsp;
                      {res.publish_date}
                    </small>
                  </Card.Footer>
                </Card>
              </Link>
            </Col>
          );
        }
        return null;
      })}
    </>
  );
};
export default WorkList;
