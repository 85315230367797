import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import Swal from "sweetalert2";
import axios from "axios";
import { authenticate } from "../../Auth/CheckUser";
import { API } from "../../../config.json";

function ReviewQuality({revData, userInfo}) {

    const isAuth = authenticate();
    const mayVote = isAuth && 
                    !revData.users_rev_quality.includes(userInfo.GUID) && // already voted
                    (revData.user_id != userInfo.GUID); // self review
   
    
    const reviewId = revData.review_id;
    const sumRevQuality = revData.sum_rev_quality;
    const countRevQuality = revData.count_rev_quality;

    const avg_review_quality = countRevQuality ? sumRevQuality / countRevQuality : 0;

    const [reviewMark, setReviewMark] = useState( avg_review_quality );
    const [canVote, setCanVote] = useState(Boolean(mayVote));
    const [err, setError] = useState("");

    const ratingChanged = (newRating) => {
        Swal.fire({
            title: "Are you sure?",
            html: "You can only rate review quality once. <br>" +  
                  "You rate this review: " + newRating,
            icon: "question",
            showCancelButton: true,
          }).then( (result) => {
            
            if (result.isConfirmed){
                
                setCanVote(false);
                setReviewMark( (newRating + sumRevQuality) / (countRevQuality+1) );
                updateDatabase(newRating, userInfo.GUID, reviewId);

            } else {
                // Double set, to be sure to change the state and cause rerender
                setReviewMark(6);setReviewMark(avg_review_quality);
            }
          
        });
        
    };

    const updateDatabase = (assessment, userId, reviewId) => {
        const completeReview = { assessment, userId, reviewId };
        const token = localStorage.getItem("Access_token");
        const options = {
            headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
            },
        };
        axios.post(
            `${API}routes/set_review_quality.php?reviewId=${reviewId}`,
            completeReview,
            options
        )
      .then((res) => {
        //need to check success or error msg
        res.data.status !== 200
          ? setError(res.data.errors)
          : Swal.fire({
              title: "Success!",
              text: `${res.data.success_msg}`,
              icon: "success",
            });
            // .then(function () {
              // window.location.href = "/dashboard";
            //   history.goBack();
            // });

        // res.status === 200 ? window.location.reload() : console.log("error");
      });

    }

    return (
        <div className="review-quality">
            <h6><strong>Quality of the review:</strong></h6>
            <div className="review-quality-inner">
                <ReactStars
                    key={reviewMark}
                    count={5}
                    onChange={ratingChanged}
                    size={20}
                    value={reviewMark+0.01}
                    edit={canVote}
                    activeColor="#ffd700"
                />
                <div className="review-quality-number"> &nbsp; { reviewMark ? reviewMark.toFixed(2) : '' } </div>
            </div>
        </div>    
    )
};

export default ReviewQuality;