import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from "../../config.json";
import * as FaColor from "react-icons/fc";
import * as AiIcons from "react-icons/ai";
import { useHistory } from "react-router-dom";
import DisplayUserInfo from "./DisplayUserInfo";

function CheckUserProfile(props) {
  const [values, setValues] = useState({
    guid: "",
  });

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const [userInfo, setuserInfo] = useState();
  const [err, setError] = useState("");

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const findNewUser = () => {
    window.location.reload();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { guid } = values;
    const body = { guid };
    await axios
      .post(`${API}routes/user_guid_search.php`, body)
      .then((res) => {
        if (res.data.status === 200) {
          setuserInfo(res.data.userInfo);
        } else if (res.data.status === 422) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  return (
    <div className="container emp-profile">
      <div style={{ float: "right" }}>
        <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
      </div>
      {userInfo !== undefined ? null : (
        <form method="post" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>GUID *</label>
            <input
              required
              type="text"
              value={values.guid}
              onChange={onChange("guid")}
              className="form-control"
              placeholder="enter guid"
            />
          </div>

          <button
            name="submit"
            type="submit"
            className="btn btn-primary btn-block"
          >
            Find User
          </button>
        </form>
      )}

      {userInfo ? (
        <>
          {" "}
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <FaColor.FcPortraitMode size={100} />
                {/* <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                            alt=""
                        /> */}
                {/* <div className="file btn btn-lg btn-primary">
                            Change Photo
                            <input type="file" name="file" />
                        </div> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="profile-head">
                <h5>
                  {userInfo.name} {userInfo.surname}
                </h5>
                <h6> {userInfo.GUID}</h6>
                {/* <p className="proile-rating">
                                RANKINGS : <span>8/10</span>
                            </p> */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <button
                onClick={findNewUser}
                className="btn btn-primary btn-block"
              >
                Find new User
              </button>
            </div>
            {/* <div className="col-md-3">
        <Link
          to={{ pathname: "/edit-profile", state: { userInfo } }}
          className="dashLink "
        >
          <button className="profile-edit-btn">Edit Profile</button>
        </Link>

        <Link
          to={{ pathname: "/change-password", state: { userInfo } }}
          className="dashLink"
        >
          <button className="profile-edit-btn mt-2">Change Password</button>
        </Link>
      </div> */}
          </div>
          <DisplayUserInfo userInfo={userInfo} />
        </>
      ) : null}
    </div>
  );
}

export default CheckUserProfile;
