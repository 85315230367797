import axios from "axios";
import React, { useState, useEffect } from "react";
import * as FaColor from "react-icons/fc";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { API } from "../../config.json";
import EditProfile from "./EditProfile";
import { useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import DisplayUserInfo from "./DisplayUserInfo";

function UserCard(props) {
  const [userInfo, setuserInfo] = useState("");

  const token = localStorage.getItem("Access_token");
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const getUserInfo = async () => {
      await axios.get(`${API}routes/user_info.php`, options).then((res) => {
        setuserInfo(res.data);
        document.cookie = "isAdmin=" + res.data.isAdmin + ";";
      });
    };
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container emp-profile">
      <div style={{ float: "right" }}>
        <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
      </div>
      <form method="post">
        <div className="row">
          <div className="col-md-4">
            <div className="profile-img">
              <FaColor.FcPortraitMode size={100} />
              {/* <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                                alt=""
                            /> */}
              {/* <div className="file btn btn-lg btn-primary">
                                Change Photo
                                <input type="file" name="file" />
                            </div> */}
            </div>
          </div>
          <div className="col-md-5">
            <div className="profile-head">
              <h5>
                {userInfo.name} {userInfo.surname}
              </h5>
              <h6> {userInfo.GUID}</h6>
              {/* <p className="proile-rating">
                                    RANKINGS : <span>8/10</span>
                                </p> */}
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    About
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <Link
              to={{
                pathname: "/edit-profile",
                state: { userInfo },
              }}
              className="dashLink "
            >
              <button className="profile-edit-btn">Edit Profile</button>
            </Link>

            <Link
              to={{
                pathname: "/change-password",
                state: { userInfo },
              }}
              className="dashLink"
            >
              <button className="profile-edit-btn mt-2">Change Password</button>
            </Link>
          </div>
        </div>
        <DisplayUserInfo userInfo={userInfo} />
      </form>
    </div>
  );
}

export default UserCard;
