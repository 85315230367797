/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../config.json";
import Swal from "sweetalert2";
import * as AiIcons from "react-icons/ai";
import { useHistory } from "react-router-dom";

function Protected(props) {
  const [err, setError] = useState("");
  // const [success, setSuccess] = useState("");
  const [subarea, setSubarea] = useState([]);
  const [values, setValues] = useState({
    title: "",
    abstract: "",
    keywords: "",
    subarea_id: "",
    //pdf: "",
  });
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };
  const deleteFields = () => {
    setValues({
      title: "",
      abstract: "",
      keywords: "",
      subarea_id: "",
    });
  };
  const token = localStorage.getItem("Access_token");
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  };

  function countWords(str) {
    const matches = str.match(/[\w\p{sc=Cyrillic}\d-]+/ugi);
    return matches ? matches.length : 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, abstract, keywords, subarea_id } = values;
    // const new_work = {
    //   title,
    //   abstract,
    //   keywords,
    //   subarea_id,
    // };

    if( countWords(keywords) < 5 ){
      setError({err:"Please insert at least 5 keywords for the work."});
      return;
    }

    const selectedFile = e.target.pdf.files[0];

    if(!selectedFile.name || ( selectedFile.size > 16*1024*1024)) { // 16MB (this size is in bytes)
      //Submit form        
      setError({err:"This file is larger then 16MB. Please upload a smaller PDF file."});
      return;
    }

    var formData = new FormData();
    formData.append("pdf", selectedFile);
    formData.append("title", title);
    formData.append("abstract", abstract);
    formData.append("keywords", keywords);
    formData.append("subarea_id", subarea_id);

    Swal.fire('Please Wait Uploading Work...');
    Swal.showLoading();
      
    await axios
      .post(`${API}routes/insert_work.php`, formData, options)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: `${res.data.message}`,
            icon: "success",
          }).then(function () {
            props.history.push("/dashboard");
          });
        } else if (res.data.status === 422) {
          Swal.close();
          setError(res.data);
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(`Error -> ${err}`);
      });
  }; // handleSubmit

  let err_msg = "";
  if (err && err.err) {
    err_msg = <div className="error-msg">{err.err}</div>;
  }
  const getSubareas = async () => {
    await axios
      .get(`${API}routes/get_all_subareas.php`, options)
      .then((res) => {
        setSubarea(res.data);
      });
  };
  useEffect(() => {
    getSubareas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div style={{ float: "right" }}>
          <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
        </div>
        <form onSubmit={handleSubmit}>
          <h3>New Work</h3>

          <div className="form-group">
            <label>Title *</label>
            <input
              required
              type="text"
              name="title"
              value={values.title}
              className="form-control"
              placeholder="Title"
              onChange={onChange("title")}
            />
          </div>

          <div className="form-group">
            <label>Abstract *</label>
            <input
              required
              type="text"
              name="abstract"
              value={values.abstract}
              className="form-control"
              placeholder="Abstract"
              onChange={onChange("abstract")}
            />
          </div>

          <div className="form-group">
            <label>Keywords *</label>
            <input
              required
              type="text"
              name="keywords"
              value={values.keywords}
              onChange={onChange("keywords")}
              className="form-control"
              placeholder="Minimum 5 keywords, separated by space"
            />
          </div>
          <div className="form-group">
            <label>Subarea *</label>
            <select
              required="required"
              type="text"
              name="subarea_id"
              value={values.subarea_id}
              className="form-control"
              placeholder="Select subarea"
              onChange={onChange("subarea_id")}
            >
              <option>Choose subarea</option>
              {subarea &&
                subarea.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.subarea}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Choose only .pdf file *</label>
            <input
              required
              type="file"
              className="form-control p-1"
              name="pdf"
            />
          </div>

          {err_msg}
          <button
            name="submit"
            type="submit"
            className="btn btn-primary mr-5 ml-3"
          >
            Add work
          </button>

          <button onClick={deleteFields} className="btn btn-danger ml-5">
            Clear
          </button>
        </form>
      </div>
    </div>
  );
}

export default Protected;
