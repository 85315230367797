import React from "react";

import Section from "../../../HOC/Section";
import kolazIMG from "../../../assets/img/kolaz.jpg";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
const home = () => {
  return (
    <Section id="home">
      <div>
        <div className="home-content p-5">
          <div className="intro container text-center text-light">
            <Card
              style={{
                boxShadow: "1px 1px 10px 20px darkgray",
                background: "darkgray",
                border: "none",
              }}
            >
              <Card.Img variant="top" src={kolazIMG} />
              <Card.Body style={{ color: "black" }}>
                <Card.Text>
                  <Link to="/dashboard">
                    <Button className="btn-dash" variant="dark" size="lg">
                      Seminar Papers & Reviews
                    </Button>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <h1 className="title"></h1>
            <h2 className="sub-title mb-4"></h2> */}
            {/* <Link target="about" classes="btn btn-primary rounded-0 mr-2">
              Learn More
            </Link>
            <Link target="contact" classes="btn btn-light text-dark rounded-0">
              Contact Us
            </Link> */}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
