export const authenticate = () => {
  const token = localStorage.getItem("Access_token");
  const token_expire = localStorage.getItem("Expire");
  let time = Math.round(new Date().getTime() / 1000);
  let isAuth = false;

  if (time > token_expire) {
    localStorage.removeItem("Expire");
    localStorage.removeItem("Access_token");
  }
  token ? (isAuth = true) : (isAuth = false);
  return isAuth;
};
