import React from "react";

import Layout from "./components/Layout/Layout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dash from "./components/Layout/Dash";
import Header from "./components/UI/Header/Header";
import Footer from "./components/UI/Footer/Footer";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import PrivateRoute from "./components/Auth/PrivateRoute";
import Protected from "./components/protected/Protected";
import SearchPage from "./components/sections/Search/SearchPage";
import NoMatch from "./components/NoMatch/NoMatch";
import UserProfile from "./components/UserProfile/UserProfile";
import EditProfile from "./components/UserProfile/EditProfile";
import ResetPWD from "./components/PWD/ResetPWD";
import ForgotPWD from "./components/PWD/ForgotPWD";
import ChangePWD from "./components/PWD/ChangePWD";
import CheckUserProfile from "./components/UserProfile/CheckUserProfile";
import OrderedWorks from "./components/sections/Browse/OrderedWorks";
import TopAuthors from "./components/sections/Browse/TopAuthors";
import ChechUserFromUrl from "./components/UserProfile/ChechUserFromUrl";
import TopReviewers from "./components/sections/Browse/TopReviewers";
import CookieConsent from "react-cookie-consent";

function App(props) {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Layout}></Route>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route path="/dashboard" component={Dash} />
        <Route exact path="/search-page" component={SearchPage} />
        <Route exact path="/reset-pwd/:guid" component={ResetPWD} />

        <Route exact path="/forgot-password" component={ForgotPWD} />
        <Route path="/change-password" component={ChangePWD} />
        <Route exact path="/check-user-profile" component={CheckUserProfile} />
        <Route
          exact
          path="/check-user-url/:guid"
          component={ChechUserFromUrl}
        />

        <Route exact path="/ordered-works" component={OrderedWorks} />
        <Route exact path="/top-reviewers" component={TopReviewers} />
        <Route exact path="/top-authors" component={TopAuthors} />

        <PrivateRoute exact path="/new_work" component={Protected} />
        <PrivateRoute exact path="/user-profile" component={UserProfile} />
        <PrivateRoute path="/edit-profile" component={EditProfile} />
        <Route exact path="" component={NoMatch} />
      </Switch>
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          backgroundColor: "#6574cd",
          color: "#fff",
          fontSize: "13px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Footer />
    </Router>
  );
}

export default App;
