import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from "../../config.json";
function ForgotPWD(props) {
  const [values, setValues] = useState({
    email: "",
  });
  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    const { email } = values;
    const body = {
      email,
    };
    e.preventDefault();
    await axios
      .post(`${API}routes/forgot_password.php`, body)
      .then((res) => {
        if (res.data.status === 200) {
          alert(res.data.mail);
        } else if (res.data.status === 422) {
          alert(res.data.mail);
        }
      })
      .catch((err) => {
        console.log(`Error -> ${err}`);
      });
  };
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleSubmit}>
          <h3>Forgot Password</h3>

          <div className="form-group">
            <label>Email address *</label>
            <input
              required
              type="email"
              value={values.email}
              onChange={onChange("email")}
              className="form-control"
              placeholder="ad@am.com"
            />
          </div>

          <button
            name="submit"
            type="submit"
            className="btn btn-primary btn-block"
          >
            Make changes
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPWD;
